@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background: white;
  color: #444;
  font-family: system-ui;
}

.-scale-x-100 {
  --tw-scale-x: -1;
}

.margin-left-auto {
  margin-left: auto;
}

// hamburger menu

.test {
  display: none !important;
}

@media only screen and (max-width: 768px) {
 
 .test {
  display: block !important;
  }
}

@media only screen and (min-width: 768px) {
 
 .test {
  display: none !important;
  }
}


